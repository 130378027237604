var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    !_vm.showPasswordChallengeSuccess && !_vm.showPasswordChallengeError
      ? _c(
          "div",
          [
            _c("b-alert", {
              attrs: { variant: "danger", show: !!_vm.errorLoginCode },
              domProps: { innerHTML: _vm._s(_vm.formErrorMsg) }
            }),
            _c("h4", { staticClass: "mb-3" }, [
              _vm._v(" " + _vm._s(_vm.$t("mfaChallenge.title")))
            ]),
            _c(
              "b-form",
              {
                on: {
                  submit: function($event) {
                    $event.stopPropagation()
                    $event.preventDefault()
                    return _vm.onSubmit.apply(null, arguments)
                  }
                }
              },
              [
                _c(
                  "b-form-group",
                  { attrs: { id: "mfa-challenge" } },
                  [
                    _c("b-form-input", {
                      attrs: {
                        id: "secret-input",
                        name: "secret-input",
                        type: "text",
                        placeholder: _vm.$t("mfaChallenge.secret"),
                        state: _vm.validateState("secret"),
                        "aria-describedby": "secret-live-feedback"
                      },
                      model: {
                        value: _vm.$v.form.secret.$model,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.$v.form.secret,
                            "$model",
                            typeof $$v === "string" ? $$v.trim() : $$v
                          )
                        },
                        expression: "$v.form.secret.$model"
                      }
                    }),
                    _c(
                      "b-form-invalid-feedback",
                      { attrs: { id: "password-live-feedback" } },
                      [
                        !_vm.$v.form.secret.required
                          ? _c("div", { staticClass: "error" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t("mfaChallenge.challengeRequired")
                                  ) +
                                  " "
                              )
                            ])
                          : _vm._e()
                      ]
                    )
                  ],
                  1
                ),
                _c(
                  "b-button",
                  {
                    staticClass: "p-3",
                    attrs: {
                      block: "",
                      type: "submit",
                      disabled: _vm.disableSubmitButton,
                      variant: "success"
                    }
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("mfaChallenge.button")) + " ")]
                )
              ],
              1
            )
          ],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }