<template>
  <div>
    <div v-if="!showPasswordChallengeSuccess && !showPasswordChallengeError">
      <b-alert
        variant="danger"
        :show="!!errorLoginCode"
        v-html="formErrorMsg"
      ></b-alert>
      <h4 class="mb-3"> {{ $t('mfaChallenge.title') }}</h4>
      <b-form @submit.stop.prevent="onSubmit">
        <b-form-group id="mfa-challenge">
          <b-form-input
            id="secret-input"
            name="secret-input"
            type="text"
            :placeholder="$t('mfaChallenge.secret')"
            v-model.trim="$v.form.secret.$model"
            :state="validateState('secret')"
            aria-describedby="secret-live-feedback"
          ></b-form-input>

          <b-form-invalid-feedback id="password-live-feedback">
            <div class="error" v-if="!$v.form.secret.required">
              {{ $t('mfaChallenge.challengeRequired') }}
            </div>
          </b-form-invalid-feedback>
        </b-form-group>
        <b-button
          class="p-3"
          block
          type="submit"
          :disabled="disableSubmitButton"
          variant="success">
          {{ $t('mfaChallenge.button') }}
        </b-button>
      </b-form>
    </div>
  </div>
</template>

<script>
import Auth from '@aws-amplify/auth';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';

export default {
  name: 'MfaChallenge',
  mixins: [validationMixin],
  props: {
    awsUser: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    form: {
      secret: null,
    },
    errorLoginCode: null,
    loadingIndicator: true,
    passwordChallengeSuccess: false,
    sendPasswordResetEmail: false,
    inputFieldDisabled: false,
    showPasswordChallengeSuccess: false,
    showPasswordChallengeError: false,
  }),
  computed: {
    formErrorMsg() {
      switch (this.errorLoginCode) {
        case 'CodeMismatchException':
          return this.$t('mfaChallenge.codeMismatchException');
        case 'NotAuthorizedException':
          return this.$t('mfaChallenge.notAuthorizedException');
        default:
          return this.errorLoginCode;
      }
    },
    disableSubmitButton() {
      return !!this.$v.form.$invalid;
    },
  },
  async created() {
    try {
      if (this.awsUser.challengeName !== 'SOFTWARE_TOKEN_MFA') {
        await this.$router.push('/oops-something-went-wrong').catch((err) => {
          throw new Error(`Routing Error occurred! ${err}.`);
        });
      }
    } catch (error) {
      console.error(error);
    }
    this.loadingIndicator = false;
  },
  validations: {
    form: {
      secret: {
        required,
      },
    },
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    validateStateReset(name) {
      const { $dirty, $error } = this.$v.formReset[name];
      return $dirty ? !$error : null;
    },
    async onSubmit() {
      this.errorLoginCode = null;
      this.loadingIndicator = true;
      await this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        this.loadingIndicator = false;
        return;
      }
      try {
        if (this.awsUser.challengeName === 'SOFTWARE_TOKEN_MFA') {
          console.log('challenge accepted!!!');
          await Auth.confirmSignIn(
            this.awsUser, // Return object from Auth.signIn()
            this.form.secret, // Confirmation code
            'SOFTWARE_TOKEN_MFA', // MFA Type e.g. SMS_MFA, SOFTWARE_TOKEN_MFA
          );
          const user = await Auth.currentAuthenticatedUser();
          console.log('loggedUser', user);
          this.$emit('verification-number-entered', user);
        }
      } catch (err) {
        console.error(err);
        this.errorLoginCode = err.code;
      }
      this.loadingIndicator = false;
    },
    showLoader(value) {
      this.loadingIndicator = !!(value);
    },
    resetComponent() {
      this.showPasswordResetSuccess = false;
      this.showPasswordResetError = false;
      this.form.email = null;
    },
  },
};
</script>

<style lang="scss" scoped>
.card-body {
  padding: 35px;
}

small {
  display: block;
}
</style>
